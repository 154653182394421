<template>
  <div>
    <!-- Gold Sponsors -->
    <h1 class="title is-3">In partnership with</h1>
    <!--
    <div class="columns is-centered is-variable is-8">
      <div class="column is-narrow">
        <p class="heading is-size-4">Pearl Sponsors</p>
        <div class="columns is-centered is-vcentered is-multiline is-mobile is-variable is-5">
          <div class="column is-narrow" v-for="sponsor in pearl">
            <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo pearl" @click="launchModal(sponsor)">
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <p class="heading is-size-4">Glitter Sponsors</p>
        <div class="columns is-centered is-vcentered is-multiline is-mobile is-variable is-5">
          <div class="column is-narrow" v-for="sponsor in glitter">
            <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo glitter" @click="launchModal(sponsor)">
          </div>
        </div>
      </div>
    </div>
    -->

    <p class="heading is-size-4">Gold Sponsors</p>
    <div class="columns is-centered is-vcentered is-multiline is-mobile is-variable is-5">
      <div class="column is-narrow" v-for="sponsor in gold">
          <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo gold" @click="launchModal(sponsor)">
      </div>
    </div>

    <!--
    <p class="heading is-size-6">Silver Sponsors</p>
    <div class="columns is-centered is-vcentered is-multiline is-mobile">
      <div class="column is-narrow" v-for="sponsor in silver">
        <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo silver" @click="launchModal(sponsor)">
      </div>
    </div>
    -->

    <div class="columns is-centered is-variable is-8">
      <div class="column is-narrow mt-5">
        <p class="heading is-size-7">Media Partners</p>
        <div class="columns is-centered is-vcentered is-multiline is-mobile">
          <div class="column is-narrow" v-for="sponsor in media">
            <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo media" @click="launchModal(sponsor)">
          </div>
        </div>
      </div>
      <div class="column is-narrow mt-5">
        <p class="heading is-size-7">Partner Organizations</p>
        <div class="columns is-centered is-vcentered is-multiline is-mobile">
          <div class="column is-narrow" v-for="sponsor in orgs">
            <img :src="`https://cdn.statically.io/img/${sponsor.logo.replace(/(https:\/\/)|-/g, '')}`" class="logo orgs" @click="launchModal(sponsor)">
          </div>
        </div>
      </div>
    </div>

    <p class="has-text-grey is-size-7 is-italic">Click on each logo to learn more</p>

    <!-- Modal -->
    <b-modal v-if="sponsorModalContent" v-model="isSponsorModalActive" :width="640" @close="onModalClose">
      <div class="card">
        <div class="card-image" v-if="sponsorModalContent.avp">
          <figure class="image is-16by9 has-background-grey">
            <iframe :src="sponsorModalContent.avp" class="has-ratio"></iframe>
          </figure>
        </div>
        <div class="card-content">
          <div class="columns is-mobile is-vcentered">
            <div class="column is-one-quarter-tablet is-one-third-mobile">
              <img :src="`https://cdn.statically.io/img/${sponsorModalContent.logo.replace(/(https:\/\/)|-/g, '')}`">
            </div>
            <div class="column has-text-left">
              <p class="title has-text-primary-dark">{{sponsorModalContent.name}}</p>
              <a v-for="website in sponsorModalContent.website" class="subtitle is-6" :href="website" target="_blank">{{website}}<br></a>
            </div>
          </div>
        </div>
        <div class="content p-5 has-text-left" style="white-space: pre-wrap;">
          <p>{{sponsorModalContent.writeup}}</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "SponsorsList",
  data() { return {
    isSponsorModalActive: false,
    sponsorModalContent: null,
    pearl: [],
    glitter: [
      {
        name: "Glambot",
        logo: "https://i.ibb.co/LZ195pH/glambot.jpg"
      }
    ],
    gold: [
      {
        name: "Clorox International Philippines",
        avp: "https://drive.google.com/file/d/1YGmrliIq7mRT3unlmBDelpUdMD4WjP7E/preview",
        logo: "https://i.ibb.co/NWFW6tP/clorox.png"
      },
      {
        name: "The Proscenium Residences",
        logo: "https://i.ibb.co/N9PV7gM/TPR-Logo-1.png",
        writeup: "Rockwell Land Corporation, a prominent developer in the Philippine real estate industry, is renowned for its commitment to crafting high-end residential and commercial developments. Specializing in creating lifestyle communities, the company has left its mark on Makati, notably through Rockwell Center, where each project reflects a dedication to quality, innovation, and fostering a sense of community.\n" +
            "\n" +
            "The Proscenium Residences is a great choice for those looking for an address within Rockwell Center Makati that's ready for your moving-in. It is the fifth tower of the complete Proscenium community, introducing luxury and sophistication. Set within an expansive 1.1-hectare amenity deck, this residential gem transcends traditional living by incorporating a world-class performing arts theatre and a business center. With an array of restaurants, stores, and a grocery area just a few minutes away, The Proscenium Residences is not just a home; it's a complete lifestyle destination. The Proscenium Link has made its debut in January 2025, further enhancing connectivity, providing residents with direct access to Power Plant Mall through Grid, the renowned food court.\n" +
            "\n" +
            "In essence, Rockwell Land Corporation continues to shape the Philippine real estate landscape with a focus on creating exceptional communities. Through projects like Edades West, Rockwell South at Carmelray, The Proscenium Residences, and 8 Benitez Suites, the company not only offers residences but a lifestyle that harmonizes with the evolving needs and aspirations of its discerning clientele\n"
      },
      {
        name: "Rockwell South at Carmelray",
        logo: "https://i.ibb.co/RYyq9zh/Revised-Carmelray-05.png",
        writeup: "Rockwell Land Corporation, a prominent developer in the Philippine real estate industry, is renowned for its commitment to crafting high-end residential and commercial developments. Specializing in creating lifestyle communities, the company has left its mark on Makati, notably through Rockwell Center, where each project reflects a dedication to quality, innovation, and fostering a sense of community.\n" +
            "\n" +
            "Rockwell South at Carmelray, another jewel in Rockwell's portfolio, presents an idyllic blend of nature-inspired living and modern convenience in Laguna. Featuring pocket parks with different themes, the development offers a warm and inviting atmosphere. Among these parks, the Barbeque Park, sprawling across 3,000 sqm, serves as a focal point, providing residents with a spacious and welcoming outdoor area. The limited lots available, ranging from 300 sqm to 564 sqm, are a testament to the high demand for this refreshing haven.\n" +
            "\n" +
            "In essence, Rockwell Land Corporation continues to shape the Philippine real estate landscape with a focus on creating exceptional communities. Through projects like Edades West, Rockwell South at Carmelray, The Proscenium Residences, and 8 Benitez Suites, the company not only offers residences but a lifestyle that harmonizes with the evolving needs and aspirations of its discerning clientele.\n"
      },
      {
        name: "Rockwell Edades West",
        logo: "https://i.ibb.co/qNQfYJM/Edades-West-Black-1.png",
        writeup: "Rockwell Land Corporation, a prominent developer in the Philippine real estate industry, is renowned for its commitment to crafting high-end residential and commercial developments. Specializing in creating lifestyle communities, the company has left its mark on Makati, notably through Rockwell Center, where each project reflects a dedication to quality, innovation, and fostering a sense of community.\n" +
            "\n" +
            "One of Rockwell Land's recent ventures, Edades West, stands as a testament to their pursuit of excellence. Launched in November 2022, this pre-selling development is strategically designed to cater to the future, turning over in October 2028.\n" +
            "\n" +
            "In response to evolving needs and lessons learned from recent events, Edades West incorporates thoughtful features such as foyer spaces for sanitization, a pioneering sky garden on the 52nd floor, and electric-vehicle charging provisions. Conveniently located near retail establishments, residents enjoy easy access to dining establishments like Ramen Ron, Single Origin, Chef Jessie, and a brief 3-5 minute walk to Power Plant Mall.\n" +
            "\n" +
            "In essence, Rockwell Land Corporation continues to shape the Philippine real estate landscape with a focus on creating exceptional communities. Through projects like Edades West, Rockwell South at Carmelray, The Proscenium Residences, and 8 Benitez Suites, the company not only offers residences but a lifestyle that harmonizes with the evolving needs and aspirations of its discerning clientele.\n"
      },
      {
        name: "Rustan Coffee Corp.",
        logo: "https://i.ibb.co/hxNwMsh/rustan-min.png"
      },
      {
        name: "Brewers-Haven Inc.",
        logo: "https://i.ibb.co/GPS9BZW/TCA-BHI-Calling-Cards-2.jpg"
      },
      {
        name: "MIB Exhibition Booth Rentals",
        logo: "https://i.ibb.co/tK7P7ws/MIB-logo.png",
        avp: "https://drive.google.com/file/d/1dty81xk8siCitGKeXrGRm82iYnsQhv-a/preview"
      },
      {
        name: "Don Macchiatos",
        logo: "https://i.ibb.co/4gQSFLw/photo-2024-02-25-03-35-07.jpg"
      }
    ],
    silver: [
      {
        name: "Rustan Coffee Corporation",
        logo: "https://i.ibb.co/hxNwMsh/rustan-min.png"
      },
      {
        name: "6MA Office Supplies Trading",
        logo: "https://i.ibb.co/2k6w208/6-MA-Logo-modified-min.png"
      }
    ],
    media: [
      {
        name: "Philippine Daily Inquirer",
        avp: "https://drive.google.com/file/d/1yQVJ83Ytwlpdj8bgWJrkoxziTdC52Sib/preview",
        logo: "https://i.ibb.co/6NnP5C0/PDI-LOGO.jpg",
        writeup: "The Philippine Daily Inquirer is undeniably the country’s most credible and influential newspaper, with more than one million nationwide readers daily.  \n" +
            "\n" +
            "Not only is it the most read among all sectors and ages, it is also the country’s most trusted source of hard-hitting news and countless exposes. \n" +
            "\n" +
            "It is the Philippines’ most awarded broadsheet with over 600 awards and citations for its editorial, business, marketing and advocacy related content and projects.\n" +
            "\n" +
            "Inquirer is recognized by both international and local award giving bodies like International News Media Association (Inma), Global Media Awards, Society of Publishers in Asia (SOPA) Awards, Catholic Mass Media Awards, , Jaime Ongpin Awards, Globe Media Excellence Awards (GMEA), Economic Journalist Association of the Philippines, Anvil Awards, Marketing Excellence Awards, People Management Association of the Philippines (PMAP) Makatao Awards, Bright Leaf Agriculture Journalism Awards, Anvil Awards, and by different schools and universities. \n" +
            "\n" +
            "With three regional bureaus, over 130 provincial correspondents nationwide, and a printing press in Laguna, Inquirer delivers news to the provinces faster and more effectively than its competitors. It also offers INQPlus, the digital edition of the print copy offering exclusive content. \n" +
            "\n" +
            "Its website, www.inquirer.net, is ranked among the world’s most visited news sites, averaging one million page views a day. \n" +
            "\n" +
            "For 38 years, the Inquirer has been at the forefront of setting the agenda by chronicling everyday events with much ardour and enthusiasm. These same virtues propelled the Inquirer to greater heights and elevated discussions on pressing issues in the society to a whole new level. \n" +
            "\n" +
            "With an end in mind to spark change in the society, it asks questions nobody else would ask, which leads to a call for action. Through this, Inquirer aims to inspire every Filipino people to become its own leader. \n" +
            "\n" +
            "Besides being the country’s leading journalistic voice, the Inquirer is also strongly committed to social responsibility and has taken an active role in various socio-civic programs. \n" +
            "\n" +
            "Its meaningful goal of making a difference in the everyday life of Filipinos continues to be the driving force behind its journalistic and corporate initiatives.  \n" +
            "\n" +
            "As the country’s number 1 newspaper, the Philippine Daily Inquirer will remain steadfast in its commitment to bring “Balanced news, fearless views” to readers when and where it matters.\n"
      }
    ],
    orgs: [
      {
        name: "Ateneo Law Debate and Advocacy Society",
        logo: "https://i.ibb.co/xsX02HS/LOGO.jpg",
        avp: "https://drive.google.com/file/d/16welhazhykpZtWdbYAAgc_D1wrtnuFo5/preview",
        writeup: "Introducing the Ateneo Law Debate and Advocacy Society (ALDAS) – a dynamic and  inclusive community dedicated to fostering debate and advocacy skills among its  members.  \n" +
            "\n" +
            "ALDAS provides a platform where law students can engage in debates, hone their  advocacy skills, and explore legal issues. Through a different range of activities such as  workshops, mock debates, and guest speaker events, members have the opportunity to  develop critical thinking, public speaking, and research abilities essential for legal  practice. \n" +
            "\n" +
            "Driven by a commitment to excellence and inclusivity, ALDAS welcomes members from  all backgrounds and levels of experience. Whether you're a seasoned debater or new to  the world of advocacy, there's a place for you in our organization. Our supportive  environment encourages collaboration, learning, and personal growth, empowering each  member to reach their full potential.  \n" +
            "\n" +
            "Join us at ALDAS and become part of our community where passionate individuals come  together to engage in meaningful discourse, advocate for change, and shape the future  of law and society."
      },
      {
        name: "Ateneo Society of International Law",
        logo: "https://i.ibb.co/zGLYpsh/asil.png",
        writeup: "The Ateneo Society of International Law (ASIL) is the official administrator of the Ateneo Law School for moot court competitions both on a national and global scale. ASIL is composed of excellent individuals who have a shared passion for mooting and international law. Continuously striving for excellence, ASIL aims to contribute to the advancement of legal education by the continuing pursuit of international law studies. The shared passion for international law translates into further studies and triumphs in international law moot court competitions. ASIL is a multi-awarded moot Society with accolades received from local, regional, and international competitions.\n" +
            "\n" +
            "In the recent 2021 Global Rounds of the Philip C. Jessup International Law Moot Court Competition, the Ateneo-Philippine team reached the Quarterfinals and bagged several awards such as Top 15 in the Preliminary and White & Case Advanced Rounds, 9th Best Overall Respondent side, 11th Best Overall Applicant side, and the Hardy C. Dillard Award for Best Combined Memorial (25th) with one of the oralists ranking in the Top 100 Best Oralists in the preliminary and advanced rounds, besting more than 500 teams from around the world. In 2022, ASIL competed in the international rounds of the ICC IBA Moot Court Competition and finished the competition strong as World Semifinalists, ranking 6th in the World Quarter-Final Rounds and 19th in the World Preliminary Rounds, after competing against more than a hundred law schools from around the world.\n" +
            "\n" +
            "Through the Ateneo International Law Review (AILR), the official publication of ASIL, and the first and foremost academic journal of its kind in the Philippines, scholarly discourse on international legal issues are encouraged and deepened. The Society has received notable victories through the years – a testament to the standard that ASIL aims to uphold.\n"
      },
      {
        name: "Intellectual Property Society of Ateneo",
        logo: "https://i.ibb.co/gTTzQY2/00-IPSA-LOGO.jpg",
        writeup: "The Intellectual Property Society of Ateneo (IPSA) is the first law school-based organization in the Philippines, driven and passionate to instill awareness and appreciation of the Intellectual Property Law through academic discourse and research. \n" +
            "\n" +
            "Driven by its passion to foster interest in, and lively discussion about the practice of Intellectual Property Law, The Intellectual Property Society of Ateneo aims to provide its members and the student body opportunities to better understand and appreciate the practice of IP Law.  \n" +
            "\n" +
            "The Intellectual Property Society of Ateneo (IPSA) strives to be the pioneering and the distinguished Intellectual Property Law student organization in the country.  IPSA aims to be the primary forum for the discussion of Intellectual Property Law, including but not limited to Patent Law, Copyright Law, and Trademark Law, in the Philippines.  \n"
      },
      {
        name: "The Palladium",
        logo: "https://i.ibb.co/3FSZTFB/The-Palladium.jpg",
        writeup: "The Palladium is the Official Student Newspaper of the Ateneo School of Law. Committed to responsible journalism inside and beyond the law school, this student publication is dedicated to reporting relevant news inside the campus, as well as beyond. Joining The Palladium entails a meaningful yet enjoyable time out from the books, cases and recitation as the Publication seeks to inform the Law School Community."
      }
      /*
      {
        name: "The Palladium",
        avp: "https://drive.google.com/file/d/1Jp7e23ulSMl_OCtmkSLtcHQd6VYfnMMi/preview",
        logo: "https://i.ibb.co/bNj1Gyk/Palladium-Colored-Logo-min.png",
        writeup: "The Palladium is the Official Student Newspaper of the Ateneo School of Law. Committed to responsible journalism inside and beyond the law school, this student publication is dedicated to reporting relevant news inside the campus, as well as beyond. Joining The Palladium entails a meaningful yet enjoyable time out from the books, cases and recitation as the Publication seeks to inform the Law School Community."
      }
       */
    ]
  }},
  methods: {
    launchModal(sponsor) {
      this.sponsorModalContent = sponsor
      this.isSponsorModalActive = true
    },
    onModalClose() {
      this.sponsorModalContent = null
    }
  }
}
</script>

<style scoped>
.heading {
  margin-bottom: 12px;
}

.logo {
  cursor: pointer;
}

.glitter, .pearl {
  max-width: 220px;
  max-height: 180px;
}

.gold {
  max-height: 132px;
  max-width: 250px;
}

.silver {
  width: 92px;
}

.orgs {
  width: 98px;
}

.media {
  width: 136px;
}
</style>