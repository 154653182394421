<template>
  <div>
    <div class="main columns is-centered about-section">
      <div class="column is-8-desktop is-10-tablet about-text">
        <h1 class="title is-1 has-text-white">About the Event</h1>

        <p class="heading is-size-4 has-text-gold">SIBOL: THE 2024 ATENEO LAW SCHOOL CAREER FAIR</p>
        <p class="has-text-white">“Sibol'' is a Tagalog term meaning “to grow; to sprout.” To the 2024 Career Fair team, this is what the Ateneo Juris Doctor Apprenticeship program stands for — it is the Ateneo Law students’ chance to begin growing the legal career they have been envisioning ever since they started dreaming of becoming a lawyer. Their Apprenticeship program is the concrete start to their legal career, and SIBOL will be their launching pad towards this growth. </p>

        <p class="heading is-size-4 has-text-gold">THE EVENT</p>
        <p class="has-text-white">The Career Fair is an annual event organized by the Ateneo Law School Student Council, planned and executed by the school’s Sophomores, and guided by the school administration and alumni association. It is a crucial event for law students, offering preparation not only for the apprenticeship program but also for their upcoming careers. The fair also aims to provide students with the necessary skills and resources for their Apprenticeship application process. Lastly, it serves as a platform for students to connect with potential law firms and organizations for future employment opportunities.</p>
      </div>
    </div>

    <section class="main has-text-centered has-background-ghost-white">
      <FirmLogoWall></FirmLogoWall>
    </section>
    <section class="main has-text-centered">
      <sponsors-list></sponsors-list>
    </section>

    <div class="faqs">
      <h1 class="title is-1 has-text-white">Frequently Asked Questions (FAQs)</h1>
      <faqs-list></faqs-list>
    </div>
  </div>
</template>

<script>
import SponsorsList from "../components/SponsorsList";
import FaqsList from "../components/FaqsList";
import FirmLogoWall from "../components/FirmLogoWall";
export default {
  name: "About",
  components: {FirmLogoWall, FaqsList, SponsorsList},
  title: "About | Sibol: Ateneo Law School Career Fair 2024"
}
</script>

<style scoped>
.main, .faqs {
  padding: 6.9%;
}

.about-section {
  background: #DA2565; background-image: radial-gradient(at -0.4% 19.1%, #DA9B50 0px, transparent 50%),radial-gradient(at 93.0% 92.5%, #A4349C 0px, transparent 50%)
}

.about-text>p {
  margin-bottom: 16px;
}

.heading {
  margin-top: 28px !important;
}

.faqs {
  background: #26CCF2; background-image: radial-gradient(at 82.9% 86.8%, #3DEAD2 0px, transparent 50%),radial-gradient(at 4.1% 14.8%, #2CB3F3 0px, transparent 50%)
}
</style>