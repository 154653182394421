<template>
  <div>
    <div class="mb-6">
      <h1 class="heading is-size-3 has-text-primary">Diamond Firms</h1>
      <div class="columns is-variable is-4-tablet is-2-mobile is-multiline is-centered is-vcentered is-mobile">
        <div v-for="firm in premium_firms" class="column is-narrow-tablet is-4-mobile has-text-centered">
          <router-link :to="`/view/${firm.id}`" target="_blank">
            <img :src="`https://cdn.statically.io/img/${firm.logo.replace(/(https:\/\/)|-/g, '')}`" class="premium-logo">
            <p class="firm-name">{{firm.name}}</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="m-4 mt-6">
      <h1 class="heading is-size-5 has-text-primary">Platinum Firms</h1>
      <div class="columns is-variable is-3-tablet is-2-mobile is-multiline is-centered is-vcentered is-mobile">
        <div v-for="firm in classic_firms" class="column is-narrow-tablet is-3-mobile has-text-centered">
          <router-link :to="`/view/${firm.id}`" target="_blank">
            <img :src="`https://cdn.statically.io/img/${firm.logo.replace(/(https:\/\/)|-/g, '')}`" class="classic-logo">
            <p class="firm-name">{{firm.name}}</p>
          </router-link>
        </div>
      </div>
    </div>
    <div class="m-4 columns is-multiline">
      <div class="column mt-6">
        <h1 class="heading is-size-5 has-text-primary">NGOs</h1>
        <div class="columns is-variable is-3-tablet is-2-mobile is-multiline is-centered is-vcentered is-mobile">
          <div v-for="firm in ngos" class="column is-narrow-tablet is-3-mobile has-text-centered">
            <router-link :to="`/view/${firm.id}`" target="_blank">
              <img :src="`https://cdn.statically.io/img/${firm.logo.replace(/(https:\/\/)|-/g, '')}`" class="classic-logo">
              <p class="firm-name">{{firm.name}}</p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="column mt-6">
        <h1 class="heading is-size-5 has-text-primary">Government Agencies</h1>
        <div class="columns is-variable is-3-tablet is-2-mobile is-multiline is-centered is-vcentered is-mobile">
          <div v-for="firm in gov" class="column is-narrow-tablet is-3-mobile has-text-centered">
            <router-link :to="`/view/${firm.id}`" target="_blank">
              <img :src="`https://cdn.statically.io/img/${firm.logo.replace(/(https:\/\/)|-/g, '')}`" class="classic-logo">
              <p class="firm-name">{{firm.name}}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {supabase} from "../supabaseClient";
export default {
  name: "FirmLogoWall",
  data() { return {
    premium_firms: [],
    classic_firms: [],
    ngos: [],
    gov: []
  }},
  mounted: async function (){
    let query = supabase
      .from('employers')
      .select('id, name, logo, premium, cat')
      .order('name', {ascending: true})
    const {data,error} = await query
    this.premium_firms = data.filter(item => item.premium)
    this.classic_firms = data.filter(item => !item.premium && item.cat === 'firms')
    this.ngos = data.filter(item => item.cat === 'ngo')
    this.gov = data.filter(item => item.cat === 'gov')
    console.log(error)
  }
}
</script>

<style scoped>
.firm-name {
  font-size: 9px;
}

.heading {
  margin-bottom: 12px;
}

@media screen and (min-width: 769px) {
  .premium-logo {
    max-height: 108px;
    max-width: 222px;
  }

  .classic-logo {
    max-height: 64px;
    max-width: 132px;
  }
}

@media screen and (max-width: 768px) {
  .classic-logo {
    max-height: 48px;
  }
}
</style>